window.addEventListener('DOMContentLoaded', initSettings);

function initSettings() {
  setInitConfig();
  toggleTheme();
  toggleDir();
}

function setInitConfig() {
  const rootEl = document.documentElement;

  const dir = localStorage.getItem('dir') || 'ltr';
  const lang = localStorage.getItem('lang') || 'en';
  const theme = localStorage.getItem('theme') || 'light';

  rootEl.dir = dir;
  rootEl.lang = lang;
  rootEl.dataset.bsTheme = theme;

  swapStyle(dir);
}

function toggleTheme() {
  const rootEl = document.documentElement;

  addEventListener('click', ({ target }) => {
    if (target.dataset?.bsToggle === 'theme') {
      const theme = rootEl.dataset.bsTheme === 'light' ? 'dark' : 'light';
      rootEl.dataset.bsTheme = theme;
      localStorage.setItem('theme', theme);
    }
  });
}

function swapStyle(dir) {
  const mainStyle = document.getElementById('main-style');
  mainStyle.href = `/assets/css/main${dir === 'rtl' ? '-rtl' : ''}.min.css`;
}

function toggleDir() {
  const rootEl = document.documentElement;

  addEventListener('click', ({ target }) => {
    if (target.dataset?.bsToggle === 'dir') {
      const dir = rootEl.dir === 'ltr' ? 'rtl' : 'ltr';
      rootEl.dir = dir;
      localStorage.setItem('dir', dir);
      swapStyle(dir);
    }
  });
}
